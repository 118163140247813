<template>
  <div>
    <!-- <loading :active.sync="loading"></loading> -->
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-btn text style="color: #1A347F;">
          <a-icon type="left" />

          <span class="fn14-weight7">Parasiticide (4)</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="pa-0">
        <div class="text-center fn24-weight7" style="color: #1A347F;">
          NexGard Family
        </div>
      </v-col>
      <v-col cols="12">
        <div v-for="(item, index) in items" :key="index">
          <v-row no-gutters align="center">
            <v-col cols="9">
              <div class="text-left mb-2 fn22" style="color: #4F4F4F;">
                {{ item.name }}
              </div>
            </v-col>
          </v-row>
          <div class="mt-4">
            <v-card
              v-for="(object, idx) in item.objects"
              :key="idx"
              class="mb-6 rounded-xl mx-auto"
              max-width="374"
              min-width="345"
              max-height="128"
              min-height="128"
              elevation="0"
              @click="goToELearning"
            >
              <v-row>
                <v-col cols="4" class="py-0 pr-0">
                  <div
                    class="rounded-l-xl py-7"
                    style="background: #BCD1FF; width: 128px; height: 128px;"
                  >
                    <img
                      class="object-cover"
                      style="display: block; margin-left: auto; margin-right: auto;"
                      height="73"
                      width="73"
                      :src="object.image"
                    />
                  </div>
                </v-col>
                <v-col cols="8" class="">
                  <div class="ml-6 text-left pb-0">
                    <span class="fn16" style="color: #081F32; font-weight: 600;"
                      >{{ object.header | limitHeader }}
                    </span>
                  </div>
                  <div class="mr-4 mt-6 text-right">
                    <span class="fn11" style="color: #081F32;"
                      >{{ object.time | limitText }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-divider class="my-4"></v-divider>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-footer
        width="100%"
        padless
        class="pa-2 ma-0 text-center"
        style="background: #F6F6F6; color: #1A347F; font-weight: 600; font-size: 10px;"
      >
        <v-col class="text-center pa-0" cols="12">
          <div class="ma-0 text-center">
            © 2010-2021 Boehringer Ingelheim International GmbH. All rights
            reserved.
          </div>
        </v-col>
      </v-footer>
    </v-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
// import footerPage from "@/components/Shared/footer";
export default {
  components: {
    draggable,
    Loading,
    // footerPage,
  },
  filters: {
    limitText(val) {
      return val.length > 80 ? val.slice(0, 70) + "..." : val;
    },
    limitHeader(val) {
      return val.length > 28 ? val.slice(0, 28) + "..." : val;
    },
  },
  data() {
    return {
      headersCategory: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูปภาพ", value: "categoryImg", align: "center" },
        { text: "ชื่อหมวดหมู่", value: "category", align: "center" },
        { text: "รายละเอียด", value: "description", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [
        {
          name: "POSM",
          objects: [
            {
              image: require("@/assets/icon/Laptop.png"),
              time: "3 days ago",
              header: "7 Skills of Highly Effective Programmers",
            },
            {
              image: require("@/assets/icon/Laptop.png"),
              time: "3 days ago",
              header: "7 Skills of Highly Effective Programmers",
            },
            {
              image: require("@/assets/icon/Laptop.png"),
              time: "3 days ago",
              header: "7 Skills of Highly Effective Programmers",
            },
          ],
        },
        {
          name: "E-Commerce Toolkit",
          objects: [
            {
              image: require("@/assets/icon/Laptop.png"),
              time: "3 days ago",
              header: "7 Skills of Highly Effective Programmers",
            },
            {
              image: require("@/assets/icon/Laptop.png"),
              time: "3 days ago",
              header: "7 Skills of Highly Effective Programmers",
            },
            {
              image: require("@/assets/icon/Laptop.png"),
              time: "3 days ago",
              header: "7 Skills of Highly Effective Programmers",
            },
          ],
        },
      ],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userBiData"))
    );
    this.getAllbrand();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiData"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbrand() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbrandpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    UpdateCate(val) {
      localStorage.setItem("CategoryData", Encode.encode(val));
      this.$router.push("EditCategory");
    },
    ViewCate(val) {
      localStorage.setItem("CategoryData", Encode.encode(val));
      this.$router.push("ViewCategory");
    },
    async DeleteCate(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/categories/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },
    goToELearning() {
      this.$router.push("learnDigitalAsset");
    },
  },
};
</script>
